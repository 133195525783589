import React, {
    useState,
    useEffect
} from 'react';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import {
    useQueryParam,
    StringParam,
    ArrayParam,
    withDefault
} from 'use-query-params';
import PropTypes from 'prop-types';
import { orderBy, throttle } from 'lodash';
import { ContentView } from './view';

import { useAppSelector, useAppDispatch } from 'utilities/redux/reduxHooks';
import { selectFavorites } from 'utilities/redux/favoritesSlice';
import { setVisibility, selectVisibility } from 'utilities/redux/filtersVisibilitySlice';

import { sortingOptions } from 'utilities/data/doors';
import { navigate } from '@reach/router';

export const Content = ({
    data,
    page,
    location
}) => {
    const favoriteValues = useAppSelector(selectFavorites);
    const [activePage, setActivePage] = useState(() => +page || 1);

    const [searchedTextQuery, setSearchedTextQuery] = useQueryParam('searchedText', withDefault(StringParam, ''));
    const [sortingQuery, setSortingQuery] = useQueryParam('sorting', withDefault(StringParam, ''));
    const [minimumPriceQuery, setMinimumPriceQuery] = useQueryParam('minimumPrice', withDefault(StringParam, ''));
    const [maximumPriceQuery, setMaximumPriceQuery] = useQueryParam('maximumPrice', withDefault(StringParam, ''));
    const [bestsellerQuery, setBestsellerQuery] = useQueryParam('bestseller', withDefault(ArrayParam, []));

    const areFiltersVisible = useAppSelector(selectVisibility);
    const dispatch = useAppDispatch();

    const setFiltersVisibility = (payload = undefined) => {
        dispatch(setVisibility(payload));
    };

    const [filters, setFilters] = useState({
        searchedText: '' || searchedTextQuery,
        sorting: '' || sortingQuery,
        minimumPrice: '' || minimumPriceQuery,
        maximumPrice: '' || maximumPriceQuery,
        bestseller: bestsellerQuery.length ? new Set().add(...bestsellerQuery) : new Set()
    });

    const createPaginationLink = (appendedPageNumber) => {
        let currentLink;
        let currentSearchQuery;
        let separatedCurrentLink;
        let generatedLink;

        // prevent from accessing window during build time
        if (typeof window !== 'undefined') {
            currentLink = window?.location?.pathname;
            currentSearchQuery = window?.location?.search || '';

            separatedCurrentLink = currentLink.split('page/');
            separatedCurrentLink[1] = appendedPageNumber;

            generatedLink = separatedCurrentLink.join('page/');
        }

        return navigate(`${generatedLink}${currentSearchQuery}`);
    };

    const setFilter = (action, key, value) => {
        setActivePage(1);
        // quick fix for link fefresh with page number when setting filters
        if (page) {
            createPaginationLink(1);
        }

        setFilters((previousFilters) => {
            let filter;

            switch (action) {
                case 'set':
                    filter = ((typeof previousFilters[key]) === 'string')
                        ? (value)
                        : (new Set([...previousFilters[key], value]));
                    break;
                case 'unset':
                    filter = ((typeof previousFilters[key]) === 'string')
                        ? ('')
                        : (new Set([...previousFilters[key]]?.filter((currentValue) => (currentValue !== value))));
                    break;
                case 'clear':
                    filter = ((typeof previousFilters[key]) === 'string')
                        ? ('')
                        : (new Set([]));
                    break;
            }

            switch (action) {
                case 'set':
                    switch (key) {
                        case 'bestseller':
                            setBestsellerQuery(value);
                            break;
                        case 'minimumPrice':
                            setMinimumPriceQuery(value);
                            break;
                        case 'maximumPrice':
                            setMaximumPriceQuery(value);
                            break;
                        case 'searchedText':
                            if (value) setSearchedTextQuery(value);
                            else setSearchedTextQuery(undefined);
                            break;
                        case 'sorting':
                            setSortingQuery(value);
                            break;
                        default:
                            break;
                    }
                    break;
                case 'unset':
                    switch (key) {
                        case 'bestseller':
                            setBestsellerQuery([]);
                            break;
                        case 'minimumPrice':
                            setMinimumPriceQuery(undefined);
                            break;
                        case 'maximumPrice':
                            setMaximumPriceQuery(undefined);
                            break;
                        case 'searchedText':
                            setSearchedTextQuery(undefined);
                            break;
                        case 'sorting':
                            setSortingQuery(undefined);
                            break;
                        default:
                            break;
                    }
                    break;
            }
            return ({
                ...previousFilters,
                [key]: filter
            });
        });
    };

    const favoriteDoors = data.allDoors.nodes
        .filter(
            (door) => Object.keys(favoriteValues?.favoriteDoors)
                .map(
                    (favoriteDoorId) => Number(favoriteDoorId)
                ).includes(door.alternative_id)
        ).map((favoriteDoor) => ({ ...favoriteDoor, type: 'door' }));

    const favoriteAccessories = data.allAccessories.nodes
        .filter(
            (accessory) => Object.keys(favoriteValues?.favoriteAccesories)
                .map(
                    (favoriteAccessoryId) => Number(favoriteAccessoryId)
                ).includes(accessory.alternative_id)
        ).map((favoriteAccessory) => ({ ...favoriteAccessory, type: 'accessory' }));

    const favoriteProducts = [...favoriteDoors, ...favoriteAccessories];

    const filteredProducts = () => {
        const filtered = favoriteProducts.filter((favoriteProduct) => {
            const filterBySearch = favoriteProduct.name.toLocaleLowerCase().includes(filters.searchedText.toLocaleLowerCase());
            const filterByMinimumPrice = favoriteProduct.price >= Number(filters.minimumPrice);
            const filterByMaximumPrice = favoriteProduct.price <= Number(filters.maximumPrice || Infinity);
            const filterByBestseller = !filters.bestseller.size || favoriteProduct.bestseller;

            const allFiltersApplied = filterBySearch && filterByMinimumPrice && filterByMaximumPrice && filterByBestseller;
            return allFiltersApplied;
        });

        const sortFiltered = () => {
            if (filters.sorting === sortingOptions.nameAsc) {
                return orderBy(filtered, 'name', 'asc');
            }
            if (filters.sorting === sortingOptions.nameDesc) {
                return orderBy(filtered, 'name', 'desc');
            }
            if (filters.sorting === sortingOptions.priceAsc) {
                return orderBy(filtered, 'price', 'asc');
            }
            if (filters.sorting === sortingOptions.priceDesc) {
                return orderBy(filtered, 'price', 'desc');
            }

            return filtered;
        };
        const filteredAndSorted = sortFiltered();
        return filteredAndSorted;
    };

    const clearFilters = () => {
        setFilters({
            searchedText: '',
            sorting: '',
            minimumPrice: '',
            maximumPrice: '',
            bestseller: new Set()
        });
        setMinimumPriceQuery(undefined);
        setMaximumPriceQuery(undefined);
        setSearchedTextQuery(undefined);
        setSortingQuery(undefined);
        setBestsellerQuery([]);
    };

    const toggleFiltersVisibility = () => {
        setFiltersVisibility();
    };

    // client doesn't want to see '/' at the end of the link, so this 'hack' removes it ("/" is present by default)
    useEffect(() => {
        if (location.href.split('ulubione').slice(-1)[0] === '/') {
            navigate(location.href.substring(0, location.href.length - 1));
        }
    }, []);

    useEffect(() => {
        setActivePage(+page || 1);
    }, [page]);

    useEffect(() => {
        if (areFiltersVisible) {
            disableBodyScroll();
        } else {
            enableBodyScroll();
        }
    }, [areFiltersVisible]);

    useEffect(() => {
        const handleResize = throttle(() => {
            if (window.matchMedia('(min-width: 768px)').matches) {
                setFiltersVisibility(false);
            }
        }, 100);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <ContentView
            filters={ filters }
            products={ filteredProducts() }
            articles={ data.allArticles.nodes }
            activePage={ activePage }
            setFilter={ setFilter }
            areBadgesVisible={
                Boolean(filters?.bestseller.size
                || filters?.sorting
                || filters?.minimumPrice
                || filters?.maximumPrice
                || filters?.searchedText)
            }
            clearFilters={ clearFilters }
            areFiltersVisible={ areFiltersVisible }
            toggleFiltersVisibility={ toggleFiltersVisibility }
        />
    );
};

Content.propTypes = {
    location: PropTypes.instanceOf(Object).isRequired,
    data: PropTypes.instanceOf(Object).isRequired,
    page: PropTypes.string.isRequired
};