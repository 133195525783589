/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import favoritesImage from '../../assets/images/favorites-img.png';

import { FiltersView } from 'utilities/components/filters/filters/component/view';
import { Field } from 'utilities/components/filters/field/component';
import { Filter } from 'utilities/components/filters/filter/component';
import { CardView } from './card/component/view';
import { ControlsView } from 'utilities/components/filters/controls/component/view';
import { DoorView } from 'utilities/components/door/component/view';
import { PaginationView } from 'utilities/components/pagination/component/view';
import { HeadlineView } from 'utilities/components/headline/component/view';
import { ArticleView } from 'utilities/components/article/component/view';
import { ButtonsView } from 'utilities/components/buttons/component/view';

import {
    sortingOptions
} from 'utilities/data/doors';

import { routes, paths } from 'utilities/routes';

import banner1 from 'assets/images/index/banner--1.jpg';

import './view.scss';
import { Img } from 'utilities/components/img/component';
import { getMainDoorImage } from 'utilities/helpers/getMainDoorImage';

SwiperCore.use([Navigation]);

const NoFavorites = () => (
    <div className="no-favorites">
        <span>
            <Link className="no-favorites__link" href={ paths.drzwiWewnetrzne }>Dodaj produkty do listy ulubionych</Link>
            <span>
                , pojawią się one tutaj.
            </span>
        </span>
        <Img className="no-favorites__image" src={ favoritesImage } alt="ulubione" />
    </div>
);

export const ContentView = ({
    products,
    articles,
    activePage,
    filters,
    setFilter,
    clearFilters,
    areFiltersVisible,
    areBadgesVisible,
    toggleFiltersVisibility
}) => {
    // offset so the page count starts from 1
    const activePageOffset = (activePage - 1);
    // Variables needed for pagination
    const LIMIT_OF_PRODUCTS_PER_PAGE = 32;
    const numberOfPages = Math.ceil(products.length / LIMIT_OF_PRODUCTS_PER_PAGE);
    const pageStart = activePageOffset * LIMIT_OF_PRODUCTS_PER_PAGE;
    const pageEnd = products.length - activePageOffset * LIMIT_OF_PRODUCTS_PER_PAGE > LIMIT_OF_PRODUCTS_PER_PAGE ? activePageOffset * LIMIT_OF_PRODUCTS_PER_PAGE + LIMIT_OF_PRODUCTS_PER_PAGE : products.length;

    // This build product types for select in filters. It is build based on the existind product types, and adds to them
    // product type which has to be as a link, which is defined in the "addedTypeLink" object
    // This whole strange 'push' situation is to preserve order of product types
    const favoritesQuery = '?favorites=Moje%20ulubione';
    const productTypes = [
        { option: 'drzwi wewnętrzne', isLink: true, link: `${paths.drzwiWewnetrzne}${favoritesQuery}` },
        { option: 'drzwi zewnętrzne', isLink: true, link: `${paths.drzwiZewnetrzne}${favoritesQuery}` },
        { option: 'drzwi techniczne', isLink: true, link: `${paths.drzwiKlatkoweITechniczne}${favoritesQuery}` },
        { option: 'Klamki', isLink: true, link: `${paths.klamki}${favoritesQuery}` }
    ];

    return (
        <>
            <section className="content">
                <FiltersView
                    areFiltersVisible={ areFiltersVisible }
                    areBadgesVisible={ areBadgesVisible }
                    clearFilters={ clearFilters }
                    toggleFiltersVisibility={ toggleFiltersVisibility }
                >
                    <h4 className="filter__headline">Nazwa modelu: </h4>
                    <Field
                        type="search"
                        placeholder="Szukaj..."
                        filter="searchedText"
                        value={ filters.searchedText }
                        setValue={ setFilter }
                    />
                    <h4 className="filter__headline">Sortowanie</h4>
                    <Field
                        name="Wybierz rodzaj sortowania"
                        type="select"
                        value={ filters.sorting }
                        filter="sorting"
                        setValue={ setFilter }
                        options={ [...Object.values(sortingOptions)] }
                    />
                    <h4 className="filter__headline">Typ produktu</h4>
                    <Field
                        name="Wybierz rodzaj drzwi"
                        type="select"
                        value={ filters.type }
                        filter="type"
                        setValue={ setFilter }
                        extendedOptionsInformation
                        options={ productTypes }
                    />
                    <Filter
                        headline="Najpopularniejsze"
                        filter="bestseller"
                        properties={ filters.bestseller }
                        setFilter={ setFilter }
                        options={ ['bestseller'] }
                        isBestseller
                    />
                    <div className="filter">
                        <h4 className="filter__headline">Cena</h4>
                        <div className="filter__range">
                            <Field
                                placeholder="Od"
                                type="text"
                                value={ filters.minimumPrice }
                                filter="minimumPrice"
                                setValue={ setFilter }
                            />
                            <Field
                                placeholder="Do"
                                type="text"
                                value={ filters.maximumPrice }
                                filter="maximumPrice"
                                setValue={ setFilter }
                            />
                        </div>
                    </div>
                </FiltersView>
                <div className="content__container">
                    <ControlsView
                        headline={ routes.ulubione.name }
                        filters={ filters }
                        areBadgesVisible={ areBadgesVisible }
                        setFilter={ setFilter }
                        clearFilters={ clearFilters }
                        toggleFiltersVisibility={ toggleFiltersVisibility }

                        isMainFilterBadgeShown
                    />
                    <div className="content__list">
                        {/* DOORS */}
                        {
                            products.length
                                ? products?.slice(pageStart, pageEnd)?.map((currentValue, index) => {
                                    const isAccesory = currentValue.type === 'accessory';
                                    const accesoryImage = { main_model: currentValue?.image };
                                    const doorImage = { main_model: getMainDoorImage(currentValue) };

                                    return (
                                        <DoorView
                                            image={ isAccesory ? accesoryImage : doorImage }
                                            headline={ currentValue?.name }
                                            collection={ currentValue?.lines?.name }
                                            price={ currentValue?.price }
                                            id={ currentValue?.alternative_id }
                                            key={ index }
                                            isAccessory={ isAccesory }
                                            bestseller={ Boolean(currentValue.bestseller) }
                                        />
                                    );
                                }) : <NoFavorites />
                        }
                    </div>
                    {
                        (LIMIT_OF_PRODUCTS_PER_PAGE < products?.length)
                        && (<PaginationView activePage={ activePage } numberOfPages={ numberOfPages } />)
                    }
                    {
                        articles.length
                            ? (
                                <div className="content__articles">
                                    <HeadlineView text="Sprawdź także" />
                                    <Swiper
                                        className="content__carousel"
                                        loop
                                        navigation={{
                                            nextEl: '.articles--next',
                                            prevEl: '.articles--previous'
                                        }}
                                        centerInsufficientSlides
                                        slidesPerView="auto"
                                        breakpoints={{
                                            1280: {
                                                slidesPerView: 'auto'
                                            }
                                        }}
                                    >
                                        {
                                            articles?.map((currentValue, index) => (
                                                <SwiperSlide className="carousel__item" key={ index }>
                                                    <ArticleView
                                                        alternative_id={ currentValue.alternative_id }
                                                        image_main={ currentValue?.image_main }
                                                        author={ currentValue?.author }
                                                        created_at={ currentValue?.created_at }
                                                        title={ currentValue?.title }
                                                        slug={ currentValue?.slug }
                                                        content={ currentValue?.lead }
                                                        reading_time={ currentValue.reading_time }
                                                        link="/"
                                                        isLarge
                                                    />
                                                </SwiperSlide>
                                            ))
                                        }
                                        <ButtonsView name="articles" />
                                    </Swiper>
                                </div>
                            )
                            : null
                    }
                    <CardView
                        image={ banner1 }
                        headline="Jak wybrać drzwi by pasowały do Twojego domu?"
                        buttonText="Jakie drzwi wewnętrzne wybrać"
                        buttonLink="/artykul/jakie-drzwi-wewnetrzne-wybrac-15"
                        additionalButtonText="Jakie drzwi zewnętrzne wybrać"
                        additionalButtonLink="/artykul/jakie-drzwi-zewnetrzne-wybrac-do-domu-16"
                    />
                </div>
            </section>
        </>
    );
};

ContentView.propTypes = {
    areFiltersVisible: PropTypes.bool.isRequired,
    areBadgesVisible: PropTypes.bool.isRequired,
    toggleFiltersVisibility: PropTypes.func.isRequired,
    products: PropTypes.instanceOf(Array).isRequired,
    articles: PropTypes.instanceOf(Array).isRequired,
    activePage: PropTypes.number.isRequired,
    filters: PropTypes.instanceOf(Object).isRequired,
    clearFilters: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired
};