import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import './view.scss';

export const PaginationView = ({ activePage, numberOfPages }) => {
    const [paginationArray, setPaginationArray] = useState([]);

    const updatePaginationList = () => {
        // if there's just 1 page, pagination component is not displayed

        // case for total of 2 pages
        if (+numberOfPages === 2) {
            // case for page 1
            if (+activePage === 1) {
                setPaginationArray([+activePage, +activePage + 1]);
            }
            // case for page 1
            if (+activePage === 2) {
                setPaginationArray([+activePage - 1, +activePage]);
            }
        }

        if (+numberOfPages >= 3) {
            // case for page 1
            if (+activePage === 1) {
                setPaginationArray([+activePage, +activePage + 1, +activePage + 2]);
            }
            // case for pages between 1 - last
            if (+activePage > 1 && +activePage < numberOfPages) {
                setPaginationArray([+activePage - 1, +activePage, +activePage + 1]);
            }
            // case for last page
            if (+activePage === numberOfPages) {
                setPaginationArray([+numberOfPages - 2, +numberOfPages - 1, +numberOfPages]);
            }
        }
    };

    useEffect(() => {
        updatePaginationList();
        // array of all page numbers (changed so the pages are displayed from number 1)
        // setPaginationArray(Array.from(Array(numberOfPages + 1).keys()).slice(1, +numberOfPages));
    }, []);

    useEffect(() => {
        updatePaginationList();
    }, [activePage, numberOfPages]);

    const createPaginationLink = (appendedPageNumber) => {
        let currentLink;
        let currentSearchQuery;
        let separatedCurrentLink;
        let generatedLink;

        // prevent from accessing window during build time
        if (typeof window !== 'undefined') {
            currentLink = window?.location?.pathname;

            // newCurrentLink fixes the issue with '/' at the end of the link when clicking on '/klamki'. This happens due to friendly links.
            // dependencies in the accesories component. It is specific to '/klamki' only
            let newCurrentLink = '';
            if (currentLink[currentLink.length - 1] !== '/') {
                newCurrentLink = `${currentLink}/`;
            } else {
                newCurrentLink = currentLink;
            }

            currentSearchQuery = window?.location?.search || '';

            separatedCurrentLink = newCurrentLink.split('page/');
            separatedCurrentLink[1] = appendedPageNumber;

            generatedLink = separatedCurrentLink.join('page/');
        }

        return `${generatedLink}${currentSearchQuery}`;
    };

    return (
        <div className="pagination">
            <div className="pagination__container">

                { `${Number(+activePage)} z ${+numberOfPages} stron` }

                <div className="pagination__progress">
                    <div className="progress__line" style={{ width: `${((+activePage / +numberOfPages) * 100)}%` }} />
                </div>

                <div className="pagination__container-navigation">
                    <a
                        href={ createPaginationLink(1) }
                        onClick={ (e) => {
                            e.preventDefault();
                            navigate(createPaginationLink(1));
                        } }
                        className={ +activePage === 1 ? 'faded' : 'active' }
                    >
                        &#171;
                    </a>
                    <a
                        href={ +activePage === 1 ? createPaginationLink(1) : createPaginationLink(+activePage - 1) }
                        onClick={ (e) => {
                            e.preventDefault();
                            if (+activePage === 1) {
                                navigate(createPaginationLink(1));
                            } else {
                                navigate(createPaginationLink(+activePage - 1));
                            }
                        } }
                        className={ +activePage === 1 ? 'faded' : 'active' }
                    >
                        &#8249;
                    </a>
                    <div>
                        { paginationArray.map((pageNumber) => (
                            <a
                                href={ createPaginationLink(+pageNumber) }
                                key={ pageNumber }
                                onClick={ (e) => {
                                    e.preventDefault();
                                    navigate(createPaginationLink(+pageNumber));
                                } }
                                className={ +activePage === +pageNumber ? 'current-page pagination__button' : 'pagination__button' }
                            >
                                {+pageNumber}
                            </a>
                        ))}
                    </div>
                    <a
                        href={ +activePage === +numberOfPages ? createPaginationLink(+numberOfPages) : createPaginationLink(+activePage + 1) }
                        onClick={ (e) => {
                            e.preventDefault();
                            if (+activePage < +numberOfPages) {
                                navigate(createPaginationLink(+activePage + 1));
                            } else {
                                navigate(createPaginationLink(+numberOfPages));
                            }
                        } }
                        className={ +activePage === +numberOfPages ? 'faded' : 'active' }
                    >
                        &#8250;
                    </a>
                    <a
                        href={ createPaginationLink(+numberOfPages) }
                        onClick={ (e) => {
                            e.preventDefault();
                            navigate(createPaginationLink(+numberOfPages));
                        } }
                        className={ +activePage === +numberOfPages ? 'faded' : 'active' }
                    >
                        &#187;
                    </a>
                </div>

            </div>
        </div>
    );
};

PaginationView.propTypes = {
    activePage: PropTypes.string.isRequired,
    numberOfPages: PropTypes.number.isRequired
};