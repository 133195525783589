import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Navigation } from 'utilities/components/navigation/component';
import { Content } from 'features/favorites';
import { Footer } from 'utilities/components/footer/component';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import SEO from 'utilities/components/seo';

export const query = graphql`
    query($id: Int) {
        allDoors(filter: { is_active: { eq: 1 }, alternative_id: { eq: $id } }) {
            nodes {
                bestseller
                alternative_id
                variants {
                    main
                    main_model
                }
                custom_variants {
                    main
                    main_model
                }
                name
                price
                price_type
            }
        }
        allAccessories(filter: { alternative_id: { eq: $id }, id: { ne: "dummy" } }) {
            nodes {
                bestseller
                alternative_id
                image
                producer_logo
                name
                price
            }
        }
        allArticles(filter: { is_active: { eq: 1 } }) {
            nodes {
                alternative_id
                author
                created_at(formatString: "DD.MM.YYYY")
                image_main
                lead
                subtype
                title
                type
                slug
                reading_time
            }
        }
    }
`;

const Favorites = ({ data, location, page }) => {
    const breadcrumbs = [routes.home, routes.ulubione];

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };

    return (
        <main className="products">
            <SEO
                title="Barański Drzwi | Moje ulubione"
                description="Barański Drzwi | Moje ulubione produkty"
                canonicalLink={ setCanonicalLink() }
                noindex
            />
            <Navigation />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <Content type="drzwi-wewnetrzne" page={ page } title="ulubione" location={ location } data={ data } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Favorites;

Favorites.propTypes = {
    data: PropTypes.string.isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    page: PropTypes.string.isRequired
};
